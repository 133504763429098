import VueRouter from 'vue-router'
import Top from "./pages/Top";
import Company from "./pages/Company";
import SalesPlace from "./pages/SalesPlace";
import Item from "./pages/Item";
import News from "./pages/News";
import NotFound from "./pages/NotFound";

export default new VueRouter({
    mode: 'history',
    base: __dirname,
    routes: [
        {
            // ルート
            path: '/',
            name: 'root',
            component: Top
        },
        {
            // トップ
            path: '/top',
            name: 'top',
            component: Top
        },
        {
            // 会社概要
            path: '/company',
            name: 'company',
            component: Company
        },
        {
            // 販売場所
            path: '/sales-place',
            name: 'sales_place',
            component: SalesPlace
        },
        {
            // 商品
            path: '/item',
            name: 'item',
            component: Item
        },
        {
            // 最新情報
            path: '/news',
            name: 'news',
            component: News
        },
        {
            // 最新情報
            path: '/news',
            name: 'news',
            component: News
        },
        {
            // 旧サイトからのリダイレクト設定
            path: '/wp_kinoko', redirect: '/top'
        },
        {
            path: '*',
            name: 'not_found',
            component: NotFound
        }
    ]
})
