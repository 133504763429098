export default {
    development: {
        apiUrls: {
            news: "/json/news.json",
            item: "/json/item.json",
            sales_place: "/json/stores.json",
            company: "/json/company.json"
        },
        googleAnalyticsId: "UA-49861697-1"
    },
    production: {
        apiUrls: {
            news: "/json/news.json",
            item: "/json/item.json",
            sales_place: "/json/stores.json",
            company: "/json/company.json"
        },
        googleAnalyticsId: "UA-49861697-1"
    }
}

