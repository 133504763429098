<template>
    <div class="root">
        <header>
            <CommonHeader />
        </header>

        <div class="contents">
            <h2>最新情報</h2>
            <div class="main">
                <div class="item" v-for="(item) in news" :key="item.name">
                    <div class="left-column">
                        <div class="image" :style="{ backgroundImage: 'url(' + item.images[0] + ')' }"></div>
                    </div>

                    <div class="right-column">
                        <a v-if=item.link v-bind:href="item.link"></a>
                        <div class="title">{{item.title}}</div>
                        <div class="written_date">{{item.written_date}}</div>
                        <div class="text">{{item.text}}</div>
                    </div>
                </div>
            </div>
        </div>
        <footer>
            <CommonFooter />
        </footer>
    </div>
</template>

<script>
    import CommonHeader from '../components/common/Header.vue'
    import CommonFooter from '../components/common/Footer.vue'
    import axios from 'axios'
    import DateUtils from "../common/DateUtils";

    export default {
        name: 'news',
        components: {
            CommonHeader,
            CommonFooter,
        },
        data () {
            return {
                news: []
            }
        },
        mounted () {
            axios.get(this.$config.apiUrls.news)
                .then((response) => {
                    this.news = response.data.news
                    for (var i = 0, len = this.news.length; i < len; ++i) {
                        this.news[i].written_date = DateUtils.getYmd(this.news[i].written_date)
                    }
                })
        }
    }
</script>

<style lang="scss" scoped>
    .main {
        margin-top: 30px;

        .item {
            display: flex;
            position: relative;
            margin: 36px auto 36px;

            .right-column  {
                margin-left: 20px;
                a {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height:100%;
                    width: 100%;
                }
            }
            .title {
                font-size: 1.5rem;
            }

            .text {
                margin-top: 12px;
            }

            .written_date {
                font-size: 1rem;
            }

            .image {
                width: 120px;
                height: 120px;
                border-radius: 50%;
                background-position: center center;
                background-size:cover;
            }
        }
    }
</style>