<template>
  <div class="root">
      <header>
          <CommonHeader />
      </header>

      <div class="contents">
          <div class="top">
              <div class="intro">
                  鈴鹿山地の麓、美しい自然環境の中で
                  きのこを栽培しています <br>
                  国産のおがくず、天然の地下水を利用して、１株ずつ丁寧に育てたきのこは、
                  豊潤な香りと歯切れの良い食感が特徴です
              </div>
              <div class="top-image">
                  <img src="/img/top/maitake-001.jpg">
              </div>
          </div>
      </div>

      <footer>
          <CommonFooter />
      </footer>
  </div>
</template>

<script>
    import CommonHeader from '../components/common/Header.vue'
    import CommonFooter from '../components/common/Footer.vue'

export default {
    name: 'top',
    components: {
        CommonHeader,
        CommonFooter
    }
}
</script>

<style lang="scss" scoped>
    .top {
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .intro {
        margin-top: 30px;
        margin-bottom: 50px;
        font-size: 16px;
        line-height: 200%;
        text-align: left;
    }
    .top-image img{
        width: 100%;
        max-width: 400px;
        height: auto;
        border-radius: 5px;
    }

</style>
