<template>
  <div class="root">
      <header>
          <CommonHeader />
      </header>

      <div class="contents">
          <h2>会社概要</h2>
          <table class="information">
              <tr>
                  <td>会社名</td>
                  <td>{{company.name}}</td>
              </tr>
              <tr>
                  <td>郵便番号</td>
                  <td>{{company.postCode}}</td>
              </tr>
              <tr>
                  <td>住所</td>
                  <td>{{company.address}}</td>
              </tr>
              <tr>
                  <td>電話</td>
                  <td>{{company.phone}}</td>
              </tr>
              <tr>
                  <td>FAX</td>
                  <td>{{company.fax}}</td>
              </tr>
              <tr>
                  <td>代表者</td>
                  <td>{{company.representative}}</td>
              </tr>
          </table>

          <div class="map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3264.2729764682213!2d136.3666273150088!3d35.09989398033389!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6003c3493a72124f%3A0x8139aca80780d8e3!2z5rC45rqQ5a-644GN44Gu44GT44K744Oz44K_44O8!5e0!3m2!1sja!2sjp!4v1588096608401!5m2!1sja!2sjp" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
          </div>
      </div>

      <footer>
          <CommonFooter />
      </footer>
  </div>
</template>

<script>
import CommonHeader from '../components/common/Header.vue'
import CommonFooter from '../components/common/Footer.vue'
import axios from 'axios'

export default {
    name: 'company',
    components: {
        CommonFooter,
        CommonHeader
    },
    data () {
        return {
            img001: require("../assets/img/test01.jpg"),
            company: {}
        }
    },
    mounted () {
        axios.get(this.$config.apiUrls.company)
            .then(response => (this.company = response.data.company))
    }
}
</script>

<style lang="scss" scoped>
    .information {
        margin-right: auto;
        margin-left: auto;
        tr {
            height: 50px;
        }
        tr td:nth-of-type(1) {
            text-justify: auto;
            width: 150px;
        }
        tr td:nth-of-type(2) {
            width: 300px;
        }
    }
    .map {
        margin-top: 30px;
        text-align: center;
        @media screen and (max-width: 480px) {
            iframe {
                width: 95%;
            }
        }
    }

</style>
