<template>
    <div class="root">
        <header>
            <CommonHeader />
        </header>

        <div class="contents">
            <h2>商品情報</h2>

            <div class="main">
                <div class="item" v-for="(item) in items" :key="item.name">
                    <div class="title">{{item.name}}</div>
                    <div class="image-article">
                        <div class="image"><img :src="item.image"></div>
                        <div>
                            <div class="article">{{item.article}}</div>
                            <div class="recipe">{{item.recipe}}</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <footer>
            <CommonFooter />
        </footer>
    </div>
</template>

<script>
    import CommonHeader from '../components/common/Header.vue'
    import CommonFooter from '../components/common/Footer.vue'
    import axios from 'axios'

    export default {
        name: 'item',
        components: {
            CommonHeader,
            CommonFooter
        },
        data () {
            return {
                items: []
            }
        },
        mounted () {
            axios.get(this.$config.apiUrls.item)
                .then(response => (this.items = response.data.items))
        }
    }
</script>

<style lang="scss" scoped>
    .main {
        margin-top: 30px;
        .item {
            .title {
                font-size: 1.5rem;
                margin: 20px auto 20px;
            }
            .image-article {
                display: flex;
                .image img{
                    width: 250px;
                }
                .article {
                    margin-left: 15px;
                }
                .recipe {
                    margin-top: 15px;
                    margin-left: 15px;
                }
            }
            @media screen and (max-width: 480px) {
                .image-article {
                    display: block;
                    text-align: center;
                    .image img{
                        width: 250px;
                    }
                    .article {
                        margin-left: 15px;
                        text-align: left;
                    }
                    .recipe {
                        margin-top: 15px;
                        margin-left: 15px;
                        text-align: left;
                    }
                }
            }
        }
    }
</style>
