<template>
    <div class="header">
        <div class="logo">
            <p class="log-image"><img src="/img/header/001.png"></p>
            <p><a href="/top">永源寺きのこセンター</a></p>
            <p class="log-image"><img src="/img/header/001.png"></p>
        </div>
        <div class="global-menu">
            <CommonNav />
        </div>
        <div class="bottom-line"></div>
    </div>
</template>

<script>
    import CommonNav from "./Nav"
    export default {
        name: 'CommonHeader',
        components: {
            CommonNav
        },
        data() {
            return {
                // img_header001 : require("./../../assets/img/header001.jpg")
            }
        },
        props: {
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .header {
        margin-left: auto;
        margin-right: auto;
        .bottom-line {
            border-top: solid #640125 5px;
            border-radius: 10px;
            width: 100%;
        }
        .logo {
            display: flex;
            @media screen and (max-width: 480px) {
                display: block;
                .log-image {
                    display: none;
                }
            }
            text-align: center;
            font-family: sans-serif;
            font-size: 24px;
            font-weight: 900;
            a:link, a:visited, a:hover, a:active {
                color: black;
                text-decoration: none;
            }
            p {
                margin: 12px auto;

                img {
                    width: 150px;
                }
            }
        }
    }
</style>
