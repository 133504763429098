export default class DateUtils {
    static getYmd(dateString) {
        let date = new Date(dateString)
        let y = date.getFullYear()
        let m = this.zeroPadding(date.getMonth() + 1,2)
        let d = this.zeroPadding(date.getDate(),2)
        return y + "/" + m + "/" + d
    }
    static zeroPadding(num,length) {
        return ('0000000000' + num).slice(-length);
    }
}