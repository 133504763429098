<template>
    <div class="footer">
        <div class="main">
            <div class="link">
                <ul>
                    <li><a href="/news">最新情報</a></li>
                    <li><a href="/company">会社概要</a></li>
                </ul>
            </div>
        </div>
        <div class="copy-right">
            <p>Copyright(C) 永源寺きのこセンター All right reserved.</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CommonFooter',
        components: {
        },
        data() {
            return {
            }
        },
        props: {
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .footer {
        margin-left: auto;
        margin-right: auto;
        margin-top: 16px;
        border-radius: 3px;
        background-color: #640125;

        .main {
            display: flex;
            justify-content: space-between;
            .link {
                padding-top: 10px;
            }
            .link ul {
                display: flex;
                margin: 0;
                li {
                    list-style: none;
                }
                li+ li {
                    border-left: 1px solid white;
                }
                li a {
                    color: white;
                    margin-left: 10px;
                    margin-right: 10px;
                    text-decoration: none;
                }
            }
            .to-top {
                width: 40px;
                height: 40px;
                background-position: center center;
                background-size:cover;
            }
        }

        .copy-right {
            color: white;
            font-size: 0.8rem;
            font-weight: 500;
            text-align: center;
            padding-bottom: 5px;

        }
    }
</style>
