<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: 'app'
    }
</script>

<style lang="scss">
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
        font-size: 1rem;
        font-weight: 500;

        .root {
            display: flex;
            flex-direction: column;
            min-height: 100vh;
        }

        .contents {
            width: 85%;
            margin-left: auto;
            margin-right: auto;
            flex: 1;
        }
    }
</style>