<template>
    <nav>
        <ul>
            <li><a href="/news">最新情報</a></li>
            <li><a href="/item">商品</a></li>
            <li><a href="/sales-place">販売場所</a></li>
            <li><a href="/company">会社概要</a></li>
        </ul>
    </nav>
</template>

<script>
    export default {
        name: 'CommonNav',
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    navi {
        margin-right: auto;
        margin-left : auto;
    }
    nav ul {
        display: table;
        table-layout: fixed;
        text-align: center;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-inline-start: 0;
    }
    nav ul li {
        display: table-cell;
        vertical-align: middle;
        font-size: 18px;
        color: #640125;
    }
    nav ul li a {
        color: #640125;
        font-weight: 300;
        text-decoration: none;
    }
</style>
