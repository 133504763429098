<template>
  <div class="root">
      <header>
          <CommonHeader />
      </header>

      <div class="contents">
          <div class="message">
              <p>404 Not Found.</p>
              <p>ページが存在しません</p>
          </div>
      </div>

      <footer>
          <CommonFooter />
      </footer>
  </div>
</template>

<script>
    import CommonHeader from '../components/common/Header.vue'
    import CommonFooter from '../components/common/Footer.vue'

export default {
    name: 'not_found',
    components: {
        CommonHeader,
        CommonFooter
    }
}
</script>

<style lang="scss" scoped>
    .message {
        font-size: 1.5rem;
        margin-top: 50px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

</style>
