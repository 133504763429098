<template>
  <div class="root">
      <header>
          <CommonHeader />
      </header>

      <div class="contents">
          <h2>販売場所</h2>

          <p class="intro">
              当センターのきのこは、主に滋賀県内の道の駅や直売所で販売しています。
              また、少量ですがスーパーなどで販売いただくこともあります。
          </p>

          <div class="store" v-for="(store) in stores" :key="store.name">
              <a v-bind:href="store.url" target="_blank"></a>
              <p class="title">
                  {{store.name}}
              </p>
              <p class="address">{{store.postalCode}} {{store.address}}</p>
<!--              <p class="article">{{store.article}}</p>-->
          </div>
      </div>

      <footer>
          <CommonFooter />
      </footer>
  </div>
</template>

<script>
import CommonHeader from '../components/common/Header.vue'
import CommonFooter from '../components/common/Footer.vue'
import axios from 'axios'

export default {
    name: 'sales_place',
    components: {
        CommonHeader,
        CommonFooter
    },
    data () {
        return {
            stores: []
        }
    },
    mounted () {
        axios.get(this.$config.apiUrls.sales_place)
            .then(response => (this.stores = response.data.stores))
    }
}
</script>

<style lang="scss" scoped>
    .contents {
        .intro {
            width: 70%;
            margin: 30px auto 50px;
            line-height: 21px;
        }

        .store {
            position: relative;
            width: 70%;
            margin: 30px auto;

            .title {
                font-size: 1.2rem;
                margin: 5px;
            }

            .address {
                font-size: 1rem;
                margin: 5px;
            }
        }
        .store a {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height:100%;
            width: 100%;
        }
    }

</style>
