import Vue from 'vue'
import VueRouter from 'vue-router'
import VueAnalytics from 'vue-analytics'
import App from './App.vue'
import router from './route'
import Config from "./common/Config"

Vue.use(VueAnalytics, {
  id: Config[process.env.NODE_ENV].googleAnalyticsId,
  router
})
Vue.use(VueRouter)
Vue.config.productionTip = false
Vue.prototype.$config = Config[process.env.NODE_ENV]

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')